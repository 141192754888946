import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [5],
		"/error/not-found": [14],
		"/experiments/counter": [15],
		"/experiments/dinero": [16],
		"/experiments/print-repeat-header": [17],
		"/login": [~18],
		"/(auth)/mini-postman": [~6,[2]],
		"/(auth)/order2": [13,[2]],
		"/(auth)/order/create": [11,[2,3]],
		"/(auth)/order/create/token": [12,[2,3]],
		"/(auth)/order/[id]/company": [7,[2,3]],
		"/(auth)/order/[id]/package": [8,[2,3]],
		"/(auth)/order/[id]/store": [9,[2,3]],
		"/(auth)/order/[id]/summary": [10,[2,3]],
		"/payment/[quotation_id]/success": [19,[4]],
		"/payment/[quotation_id]/wait-for-confirmation": [20,[4]],
		"/quotation/[quotation_id]": [21],
		"/sentry-example": [22]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';